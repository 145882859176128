"use client";
import React, { useState, useEffect, forwardRef, useCallback } from "react";
import ActiveCircle from "../../assets/img/premiumJourney/active-circle.png";
import Profile from "../PremiumJourneys/Profile";
import { isMobile } from "react-device-detect";

interface PremiumSidebarProps {
    visible: boolean;
    handleOutsideClick?: () => void;
}

const LeftPremiumSidebar = forwardRef<HTMLDivElement, PremiumSidebarProps>(
    ({ visible, handleOutsideClick }, ref) => {
        const years = ["1", "2", "3", "4", "5", "6+"];
        const quarters = [1, 2, 3, 4];

        const [selectedYear, setSelectedYear] = useState<number | string>(0);
        const [selectedItem, setSelectedItem] = useState<string | null>("Profile Analysis");
        const [activeQuarter, setActiveQuarter] = useState<number>(0);

        const scrollToElement = (id: string) => {
            const targetElement = document.getElementById(id);
            if (targetElement) {
                targetElement.scrollIntoView({ behavior: "smooth" });
            }
        };

        const handleYearClick = useCallback(
            (year: number | string) => {
              setSelectedYear(year);
              setSelectedItem(null);
              setActiveQuarter(0); 
    
              if (handleOutsideClick  ) {
                setTimeout(() => handleOutsideClick(), year === "1"?3000 : 1000);
              }
              scrollToElement(year === "6+" ? "year6" : `year${year}`);
            },
            [handleOutsideClick]
          );
          
          const handleQuarterClick = useCallback(
            (event: React.MouseEvent, quarter: number) => {
              event.preventDefault();
              event.stopPropagation();
              setActiveQuarter(quarter);
    
              if (handleOutsideClick) setTimeout(() => handleOutsideClick(), 1000);
              scrollToElement(`quarter${quarter}`);
            },
            [handleOutsideClick, selectedYear]
          );
    

          const handleItemClick = useCallback(
            (item: string) => {
              setSelectedItem(item);
              setSelectedYear(0);
              setActiveQuarter(0);
              if (handleOutsideClick) setTimeout(() => handleOutsideClick(), 1000);
              scrollToElement(item);
            },
            [handleOutsideClick]
          );
      

        const renderItem = useCallback(
            (item: string) => (
                <div key={item} className="relative mb-6">
                    <div
                        onClick={() => handleItemClick(item)}
                        className={`flex items-center cursor-pointer ${selectedItem === item ? "text-[#0778B6]" : "text-gray-500"
                            }`}
                    >
                        <div
                            className={`w-6 h-6 rounded-full border mr-1 ${selectedItem === item ? "" : "border-slate-300"
                                } bg-white z-10`}
                        >
                            {selectedItem === item && (
                                <img src={ActiveCircle} alt="active" />
                            )}
                        </div>
                        <span className="font-medium text-sm">{item.toUpperCase()}</span>
                    </div>
                    {item !== 'Summary' && <div className="absolute left-2.5 top-7 h-full w-px border-dashed border-l-2"></div>}
                </div>
            ),
            [selectedItem, handleItemClick]
        );

        if (!visible) return null;

        return (
            <div ref={ref} className="flex flex-col items-center gap-4">
                {!isMobile && <Profile />}
                <div className="custom-sidebar-width w-52 main-box-shadow rounded-lg custom-sidebar-height overflow-y-scroll">
                    <div className="flex flex-col pt-3 pl-4 sm:pl-3 lg:pl-4 bg-white rounded-lg relative">
                        {renderItem("Profile Analysis")}

                        {years.map((year) => (
                            <div key={year} className="relative mb-6">
                                <div
                                    onClick={() => handleYearClick(year)}
                                    className={`flex items-center cursor-pointer ${selectedYear === year ? "text-[#0778B6]" : "text-gray-500"
                                        }`}
                                >
                                    <div
                                        className={`w-6 h-6 rounded-full border mr-1 ${selectedYear === year ? "" : "border-slate-300"
                                            } bg-white z-10`}
                                    >
                                        {selectedYear === year && (
                                            <img src={ActiveCircle} alt="active" />
                                        )}
                                    </div>
                                    <span className="font-medium text-sm">YEAR {year}</span>
                                </div>
                                <div className="absolute left-2.5 top-7 h-full w-px border-dashed border-l-2"></div>

                                {selectedYear === year && year === "1" && (
                                    <div className="ml-6 relative mt-2 ">
                                        {quarters.map((quarter) => (
                                            <div
                                                key={quarter}
                                                onClick={(e) => handleQuarterClick(e, quarter)}
                                                className={`flex items-center cursor-pointer font-semibold text-sm ${activeQuarter === quarter
                                                        ? "text-orange-500 font-medium"
                                                        : "text-gray-500"
                                                    }`}
                                            >
                                                <div
                                                    className={`arrow right ${activeQuarter === quarter
                                                            ? "border-orange-500"
                                                            : "border-gray-500"
                                                        }`}
                                                ></div>
                                                <span>Quarter {quarter}</span>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        ))}

                        {renderItem("Alternate Journeys")}
                        {renderItem("Industry Trends")}
                        {renderItem("Personal Growth")}
                        {renderItem("Summary")} {/* Re-added the Summary section */}
                    </div>
                </div>
            </div>
        );
    }
);

export default React.memo(LeftPremiumSidebar);
