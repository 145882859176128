import React, { useEffect, useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import google from "../assets/img/google.png";
import login from "../assets/img/login.png";
import { useNavigate, Link, useLocation } from "react-router-dom"; // Ensure useLocation is imported
import { CheckEmail } from "../api/CheckEmail";
import Loginnav from "../components/Navbar/loginNav";
import LoadingSpinner from "../components/common/LoadingSpinner";
import { isUserTokenValid } from "../components/common/ValidateToken";

interface LoginProps {
  clientId: string;
}

const Login: React.FC<LoginProps> = ({ clientId }) => {
  const location = useLocation(); // Get the current location
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { page } = location.state || { page: 'No text provided' };

  // Log the current URL
  useEffect(() => {
    const checkTokenAndSetupLogin = async () => {
      setLoading(true);
      const isValid = await isUserTokenValid();
      if (isValid) {
        navigate('/home');
      }
      setLoading(false);
    };

    checkTokenAndSetupLogin();
  }, [navigate, location]);

  const googleLogin = useGoogleLogin({
    onSuccess: async (response) => {
      setLoading(true);
      try {
        const data = await CheckEmail(response.access_token);
        if (data) {
          localStorage.setItem('AccessToken', response.token_type + " " + response.access_token);
          setSuccessMessage("Login Success");
          if (page === "counselling") {
            navigate("/counselling");
          }else if (page === "pcj"){
            navigate('/premiumjourney');
          }  
          else {
            navigate('/home');
          }
        }
      } catch (error) {
        console.error("Error during login:", error);
      } finally {
        setLoading(false);
      }
    },
  });

  const log = async () => {
    if (location.search === "?SkillJourney_qa" && !window.location.href.includes("https://www.skilljourney.in/")) {
      setLoading(true);
      try {
        localStorage.setItem("AccessToken", "Bearer 2J8K9L5M4N1Q7X3B6V0Z$skilljourney01@gmail.com");
        localStorage.setItem("Name", "TestUser");
        const data = await CheckEmail("2J8K9L5M4N1Q7X3B6V0Z$skilljourney01@gmail.com");
        if (data) {
          setSuccessMessage("Login Success");
          if (page === "counselling") {
            navigate("/counselling");
          } else if (page === "pcj"){
            navigate('/premiumjourney');
          } 
          else {
            navigate('/home');
          }
        }
      } catch (error) {
        console.error("Error during login:", error);
      } finally {
        setLoading(false);
      }
    } else {
      googleLogin(); // Call the Google login function
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="relative flex flex-col md:flex-row md:h-screen h-screen overflow-y-auto">
      <div className="flex-1 flex flex-col md:justify-between md:w-1/2">
        <Loginnav />
        <div className="flex-1 md:absolute md:right-0 md:top-0 md:bottom-0 md:w-1/2 bg-orange-100 rounded-custom-70px-tl-tr md:rounded-custom-90px-tl-br md:p-16 flex flex-col justify-start items-center shadow-mobiledrop md:shadow-custom relative mt-72 md:mt-auto">
          <img
            src={login}
            alt="Login"
            className="w-80 h-64 left-1/2 -top-40 md:w-[500px] md:h-[400px] absolute md:top-1/2 md:-left-32 transform -translate-x-1/2 -translate-y-1/2"
          />
          <span className="text-3xl md:text-5xl py-5 md:py-10 font-bold md:mt-28 lg:mt-36 md:ml-20 bg-custom-gradient text-transparent bg-clip-text">
            LOG IN
          </span>
          <div className="flex items-center justify-center font-sans md:ml-20">
            <span className="md:text-xl text-lg font-bold text-center leading-tight">
              Welcome to SkillJourney! <br />
              <span className="font-semibold">Your Path to a Bright Future starts here.</span>
            </span>
          </div>

          <div className="flex items-center justify-center py-5 md:py-3 md:ml-20">
            <button
              onClick={log}
              className="relative w-auto px-6 py-2.5 md:text-2xl text-lg font-semibold tracking-wider text-white transition-all duration-300 transform md:w-96 md:h-16 md:mx-4 focus:outline-none bg-custom-yellow-orange rounded-full shadow-drop"
            >
              <span className="absolute left-4 top-1/2 transform -translate-y-1/2 ml-4">
                <img className="h-8 w-8" src={google} alt="Google Logo" />
              </span>
              <span className="pl-14 ">Continue With Google</span>
            </button>
          </div>
          <p className="md:text-base py-1 text-sm font-semibold font-sans text-center md:ml-20 md:mt-4">
            By signing you are agreeing to our{" "}
            <Link to="/privacypolicy" className="text-custom-blue underline">
              Privacy Policy
            </Link>
          </p>
          {successMessage && (
            <div className="text-green-500 text-2xl">{successMessage}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
