import axios from 'axios';
import { AdminApi } from './apiConfig/ApiFormatter';

export const SendThankingEmail = async (): Promise<any> => {
    const apiUrl = `${AdminApi()}sendEmail`;
    const email = localStorage.getItem("Email");
    const username = localStorage.getItem("Name");

    // Updated HTML content for the email body
    const emailBody = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Premium Career Journey Ready</title>
        <style>
            body {
                font-family: Arial, sans-serif;
                background-color: #f9f9f9;
                margin: 0;
                padding: 20px;
            }
            .container {
                background-color: #ffffff;
                border-radius: 8px;
                box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
                padding: 20px;
                max-width: 600px;
                margin: auto;
            }
            .logo {
                text-align: center;
                margin-bottom: 20px;
            }
            .logo img {
                max-width: 150px; /* Adjust as necessary */
            }
            h1 {
                color: #333;
            }
            p {
                color: #555;
                line-height: 1.6;
            }
            .link {
                display: inline-block;
                margin-top: 15px;
                padding: 10px 15px;
                background-color: #007bff;
                color: white;
                text-decoration: none;
                border-radius: 5px;
            }
            .link:hover {
                background-color: #0056b3;
            }
            .footer {
                margin-top: 20px;
                font-size: 0.9em;
                color: #777;
            }
        </style>
    </head>
    <body>
        <div class="container">
            
            <p>Dear ${username},</p>

            <p>We are delighted to inform you that your payment is received, and you are successfully upgraded to a Premium User.</p>

            <p>Your personalized Premium Career Journey is currently being crafted. Please allow up to <b>48 hours</b> for its completion. As soon as your journey is ready, we will notify you via email.</p>

            <p>Thank you for choosing SkillJourney. We are excited to accompany you on your career path.</p>

            <p>Sincerely,<br>The SkillJourney Team</p>
        </div>
    </body>
    </html>
    `;

    const requestBody = {
        user_email: email,
        sender_email: "admin@skilljourney.in",
        subject: "Thank you for your payment.",
        body: emailBody, // HTML content for the body
        body_type: "HTML", // Specify that the body is HTML
        bcc_recipients: ["ameya.naik@skilljourney.in","saurabh.deshpande@skilljourney.in"],
    };

    try {
        const response = await axios.post(apiUrl, requestBody, {
            headers: {
                'Authorization': `${localStorage.getItem('AccessToken')}`,
                'Content-Type': 'application/json'
            }
        });

        return response.data; // Return the response data
    } catch (error) {
        console.error('Error sending email:', error);
    }
};
