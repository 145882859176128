import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import CreateNavbar from '../Navbar/Navbar';
import Rating from '@mui/material/Rating'; // Import the Rating component from Material-UI
import { RazorpayButton } from '../common/CounsellorPayement';

interface AvailableSlot {
  date: string; // Format: "DD/MM/YYYY"
  slots: string[];
}

interface CounselorApiResponse {
  email: string;
  name: string;
  contact: number | null;
  profile_url: string;
  bio: string;
  additional_info: string;
  address: string;
  available_slots: {
    AvailableDates: AvailableSlot[];
  };
  specialty: string;
  rating: number;
}

export interface Counselor {
  id: number;
  name: string;
  email: string;
  specialty: string;
  rating: number;
  photoUrl: string;
  bio: string;
  availableSlots: AvailableSlot[];
  additional_info: string;
}

const pricing = 999; // Example price

const BookSessionPage: React.FC = () => {
    const location = useLocation();
    const counselor = location.state?.counselor as Counselor;

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [mobileNumber, setMobileNumber] = useState<string>('');
    const [couponCode, setCouponCode] = useState<string>('SKILL_OFFER_50');
    const [discount, setDiscount] = useState<number>(0);
    const [selectedDate, setSelectedDate] = useState<string>('');
    const [selectedSlot, setSelectedSlot] = useState<string>('');
    const [nameError, setNameError] = useState<string>('');
    const [emailError, setEmailError] = useState<string>('');
    const [mobileError, setMobileError] = useState<string>('');
    const [slotError, setSlotError] = useState<string>('');
    const [dateError, setDateError] = useState<string>('');
    const [shouldRenderRazorpay, setShouldRenderRazorpay] = useState<boolean>(false);

    const formRef = useRef<HTMLFormElement>(null);

    useEffect(() => {
        // Scroll to the top of the page
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        setDiscount(50 || 0);
        const storedEmail = localStorage.getItem('Email');
        if (storedEmail) {
            setEmail(storedEmail);
        }
    }, []);

    const handleCouponChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const code = e.target.value;
        setCouponCode(code);

        // Example coupon logic
        const validCoupons: { [key: string]: number } = {
            'COUNSEL10': 10,
            'COUNSEL20': 20,
            'SKILL_OFFER_50': 50,
            'SKILL_COUNSEL_70': 70,
            'SKILL2SUCCESS':99.85,
            'CAREERCLARITY':80
        };
        const percentage = validCoupons[code];
        setDiscount(percentage || 0);
    };

    const handleMobileNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setMobileNumber(value);

        const mobilePattern = /^[0-9]{10}$/;
        if (mobilePattern.test(value)) {
            setMobileError('');
        } else {
            setMobileError('Please enter a valid 10-digit mobile number');
        }
    };

    const validateForm = (): boolean => {
        let isValid = true;
        setNameError('');
        setEmailError('');
        setMobileError('');
        setSlotError('');
        setDateError('');

        if (!name) {
            setNameError('Full Name is required');
            isValid = false;
        }

        if (!email) {
            setEmailError('Email is required');
            isValid = false;
        } else {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailPattern.test(email)) {
                setEmailError('Please enter a valid email address');
                isValid = false;
            }
        }

        if (!mobileNumber) {
            setMobileError('Mobile number is required');
            isValid = false;
        } else {
            const mobilePattern = /^[0-9]{10}$/;
            if (!mobilePattern.test(mobileNumber)) {
                setMobileError('Please enter a valid 10-digit mobile number');
                isValid = false;
            }
        }

        if (!selectedDate) {
            setDateError('Date is required');
            isValid = false;
        }

        if (!selectedSlot) {
            setSlotError('Time slot is required');
            isValid = false;
        }

        return isValid;
    };

    const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (validateForm()) {
            setShouldRenderRazorpay(true);
        } else {
            setShouldRenderRazorpay(false);
        }
    };

    const convertToDateISO = (dateStr: string): string => {
        const [day, month, year] = dateStr.split('/').map(Number);
        return new Date(year, month - 1, day).toISOString().split('T')[0];
    };

    // Get today's date and the date for tomorrow
    const todayISO = new Date().toISOString().split('T')[0];
    const tomorrowISO = new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().split('T')[0];

    // Filter available dates to only include dates from tomorrow onwards
    const availableDates = counselor.availableSlots
        .filter(slot => convertToDateISO(slot.date) >= tomorrowISO) // Filter to include only tomorrow and future dates
        .map(slot => slot.date);

    const slotsByDate = counselor.availableSlots
        .reduce((acc, slot) => {
            acc[slot.date] = slot.slots;
            return acc;
        }, {} as Record<string, string[]>);

    const filteredSlots = selectedDate ? slotsByDate[selectedDate] || [] : [];

    const discountAmount = ((pricing * discount) / 100).toFixed(2);
    const discountedPrice = pricing - parseFloat(discountAmount);
    const gstAmount = (discountedPrice * 0.18).toFixed(2);
    const total = (discountedPrice + parseFloat(gstAmount)).toFixed(2);

    return (
        <>
            <CreateNavbar page='home' />
            <section className=''></section>

            <div className="bg-gray-100 min-h-screen p-6">
                <main className="container mx-auto flex flex-col lg:flex-row gap-6">
                    {/* Counselor Card Section */}
                    <section className="bg-white p-8 rounded-lg shadow-md flex-1 min-h-[300px] lg:w-1/2 border-t-4 border-custom-orange">
                        <div className="border-b-2 border-gray-200 pb-4 mb-6">
                            <h2 className="text-2xl font-semibold text-gray-800 mb-2">Counselor Details</h2>
                        </div>
                        <div className="flex flex-col items-center text-center">
                            <img
                                src={counselor.photoUrl}
                                alt={counselor.name}
                                className="w-32 h-32 rounded-full border-4 border-custom-orange mb-4"
                            />
                            <h3 className="text-2xl font-semibold mb-2 text-gray-800">{counselor.name}</h3>
                            <p className="text-gray-600 mb-2"><strong>Specialty:</strong> {counselor.specialty}</p>
                            <div className="flex justify-center mt-1">
                                <div className="justify-center text-gray-600 text-center mb-2">
                                    <strong>Rating:</strong>
                                </div>
                                <div className="justify-center py-1 pl-2">
                                    <Rating
                                        name={`rating-${counselor.id}`}
                                        value={counselor.rating}
                                        precision={0.5}
                                        readOnly
                                        size="small"
                                    />
                                </div>
                            </div>
                            <p className="text-gray-600"><strong>Bio:</strong> {counselor.bio}</p>
                            <p className="text-gray-600"><strong>Additional Information:</strong> {counselor.additional_info}</p>
                        </div>
                    </section>

                    {/* Booking Form Section */}
                    <section className="bg-white p-8 rounded-lg shadow-md flex-1 min-h-[300px] lg:w-1/2 border-t-4 border-custom-orange">
                        <div className="border-b-2 border-gray-200 pb-4 mb-6">
                            <h2 className="text-2xl font-semibold text-gray-800 mb-2">Book a Counseling Session</h2>
                        </div>
                        <form id="booking-form" ref={formRef}>
                            <label className="block mb-4">
                                <span className="font-medium text-gray-700">Full Name:</span>
                                <input
                                    type="text"
                                    value={name}
                                    placeholder="Enter your full name"
                                    required
                                    onChange={(e) => setName(e.target.value)}
                                    className={`mt-1 block w-full px-3 py-2 border ${nameError ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-custom-orange focus:border-custom-orange`}
                                />
                                {nameError && <p className="text-red-500 text-sm mt-1">{nameError}</p>}
                            </label>

                            <label className="block mb-4">
                                <span className="font-medium text-gray-700">Email:</span>
                                <input
                                    type="email"
                                    value={email}
                                    placeholder="Enter your email"
                                    required
                                    onChange={(e) => setEmail(e.target.value)}
                                    className={`mt-1 block w-full px-3 py-2 border ${emailError ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-custom-orange focus:border-custom-orange`}
                                />
                                {emailError && <p className="text-red-500 text-sm mt-1">{emailError}</p>}
                            </label>

                            <label className="block mb-4">
                                <span className="font-medium text-gray-700">Mobile Number:</span>
                                <input
                                    type="text"
                                    value={mobileNumber}
                                    placeholder="Enter your mobile number"
                                    required
                                    onChange={handleMobileNumberChange}
                                    className={`mt-1 block w-full px-3 py-2 border ${mobileError ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-custom-orange focus:border-custom-orange`}
                                />
                                {mobileError && <p className="text-red-500 text-sm mt-1">{mobileError}</p>}
                            </label>

                            <label className="block mb-4">
                                <span className="font-medium text-gray-700">Select Date:</span>
                                <select
                                    value={selectedDate}
                                    onChange={(e) => {
                                        setSelectedDate(e.target.value);
                                        setSelectedSlot('');
                                    }}
                                    className={`mt-1 block w-full px-3 py-2 border ${dateError ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-custom-orange focus:border-custom-orange`}
                                >
                                    <option value="">Select a date</option>
                                    {availableDates.map(date => (
                                        <option key={date} value={date}>{date}</option>
                                    ))}
                                </select>
                                {dateError && <p className="text-red-500 text-sm mt-1">{dateError}</p>}
                            </label>

                            {selectedDate && (
                                <label className="block mb-4">
                                    <span className="font-medium text-gray-700">Select Time Slot:</span>
                                    <select
                                        value={selectedSlot}
                                        onChange={(e) => setSelectedSlot(e.target.value)}
                                        className={`mt-1 block w-full px-3 py-2 border ${slotError ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-custom-orange focus:border-custom-orange`}
                                    >
                                        <option value="">Select a time slot</option>
                                        {filteredSlots.map(slot => (
                                            <option key={slot} value={slot}>{slot}</option>
                                        ))}
                                    </select>
                                    {slotError && <p className="text-red-500 text-sm mt-1">{slotError}</p>}
                                </label>
                            )}

                            <label className="block mb-4">
                                <span className="font-medium text-gray-700">Coupon Code:</span>
                                <input
                                    type="text"
                                    value={couponCode}
                                    placeholder="Enter coupon code"
                                    onChange={handleCouponChange}
                                    className={`mt-1 block w-full px-3 py-2 border ${discount ? 'border-green-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-custom-orange focus:border-custom-orange`}
                                />
                                {discount > 0 && <p className="text-green-500 text-sm mt-1">Discount applied: {discount}%</p>}
                            </label>
                        </form>
                    </section>
                </main>

                {/* Payment Summary Section */}
                <section className="bg-white p-8 rounded-lg shadow-md mt-6 border-t-4 border-custom-orange lg:w-1/2 mx-auto">
                    <div className="border-b-2 border-gray-200 pb-4 mb-6">
                        <h2 className="text-2xl font-semibold text-gray-800 mb-2">Payment Summary</h2>
                    </div>
                    <div className="space-y-4">
                        <div className="flex justify-between">
                            <span className="font-medium text-gray-700">Counseling Fee:</span>
                            <span className="font-medium text-gray-800">₹{pricing.toFixed(2)}</span>
                        </div>
                        {discount > 0 && (
                            <>
                                <div className="flex justify-between">
                                    <span className="font-medium text-gray-700">Discount ({discount}%):</span>
                                    <span className="font-medium text-red-500">- ₹{discountAmount}</span>
                                </div>
                                <div className="flex justify-between">
                                    <span className="font-medium text-gray-700">Discounted Price:</span>
                                    <span className="font-medium text-gray-800">₹{discountedPrice.toFixed(2)}</span>
                                </div>
                            </>
                        )}
                        <div className="flex justify-between">
                            <span className="font-medium text-gray-700">GST (18%):</span>
                            <span className="font-medium text-gray-800">₹{gstAmount}</span>
                        </div>
                        <div className="flex justify-between border-t-2 border-gray-200 pt-4">
                            <span className="font-semibold text-xl text-gray-800">Total Payable:</span>
                            <span className="font-semibold text-xl text-gray-800">₹{total}</span>
                        </div>
                        <div className="flex py-2 justify-center">
                            <button
                                type="submit"
                                onClick={handleButtonClick}
                                className=""
                            >
                                {shouldRenderRazorpay ? (
                                    <RazorpayButton
                                        buttonText="Proceed to Pay"
                                        amount={total}
                                        course_type="Counseling"
                                        name={name}
                                        useremail={email}
                                        phone={mobileNumber}
                                        counsellor_email={counselor.email}
                                        title={`Counseling Session with ${counselor.name}`}
                                        date={selectedDate}
                                        slot={selectedSlot}
                                    />
                                ) : (
                                    <button
                                        type="submit"
                                        className="w-full py-3 px-4 bg-custom-orange text-white rounded-md shadow-md hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-custom-orange mt-6"
                                    >
                                        Validate Information
                                    </button>
                                )}
                            </button>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default BookSessionPage;