import { BackendApi } from "./apiConfig/ApiFormatter";



export const get_counsellor = async (): 
    Promise<any> => 
    {
        const apiUrl = BackendApi() + 'get_all_counsellors';
        // console.log(`Api URL ${apiUrl}`);
        const idToken = localStorage.getItem('AccessToken'); // Fetch the ID token from session storage
        console.log(idToken)
        try {
            const response:any = await fetch(apiUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${idToken}`
                }            
            });
            const data: any = await response.json();
         
            if (!data) {
                // console.log('No counsellor Details Found');
                throw new Error('No Payment Details Found');
            }
            return data;
        } catch (error) {
            console.error('Error fetching Counsellor Data:', error);
            throw error;  // Optionally, rethrow the error
        }
    };

    export const fetchBookings = async (): Promise<any> => {
        const apiUrl = BackendApi() + 'fetchBookings';
        const email = localStorage.getItem('Email'); // Fetch the ID token from local storage
        const idToken = localStorage.getItem('AccessToken'); // Fetch the ID token from session storage

        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${idToken}`

                },
                body: JSON.stringify({ "user_email": email }) // Send the body with GET
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const data = await response.json();
            
            if (!data) {
                throw new Error('No Payment Details Found');
            }
            
            return data;
        } catch (error) {
            console.error('Error fetching bookings:', error);
            throw error;  // Optionally, rethrow the error
        }
    };

    export const fetchCounsellorBookings = async (): Promise<any> => {
        const apiUrl = BackendApi() + 'fetchBookings';
        const email = localStorage.getItem('Email'); // Fetch the ID token from local storage
        const idToken = localStorage.getItem('AccessToken'); // Fetch the ID token from session storage

        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${idToken}`

                },
                body: JSON.stringify({ "counsellor_email": email }) // Send the body with GET
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const data = await response.json();
            
            if (!data) {
                throw new Error('No Payment Details Found');
            }
            
            return data;
        } catch (error) {
            console.error('Error fetching bookings:', error);
            throw error;  // Optionally, rethrow the error
        }
    };


    export const cancelMeeting = async (
        counsellor_email:string,
        date:any,
        time:any,
        timestamp:any
    ): Promise<any> => {
        const apiUrl = BackendApi() + 'cancelMeeting';
        const email = localStorage.getItem('Email'); // Fetch the ID token from local storage
        const idToken = localStorage.getItem('AccessToken'); // Fetch the ID token from session storage

        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${idToken}`

                },
                body: JSON.stringify({ "user_email": email,'counsellor_email':counsellor_email,"date":date,"time":time,"timestamp":timestamp }) // Send the body with GET
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const data = await response.json();
            
            if (!data) {
                throw new Error('No Payment Details Found');
            }
            
            return data;
        } catch (error) {
            console.error('Error fetching bookings:', error);
            throw error;  // Optionally, rethrow the error
        }
    };
    